import { Text, Image } from "react-native";
import { View } from "react-native-web";
import { styles } from "../../styles/styles";

export default function Error404(props) {
	let { navigation, route } = props;

	return (
		<Text style={styles.errorPage}>La página que buscas no existe...</Text>
	);
}
