import { useState } from "react";
import { Text, TextInput, View } from "react-native";
import { getIsEndTimeAfterStartTime, getTimeStringFromTimeObject } from "../../helpers/time";
import { styles, placeholderTextColor, invalidPlaceholderTextColor } from "../../styles/styles";
import Input from "./Input";

export default function TimerInput(props) {
	const [inputValue, setInputValue] = useState(props.time ? getTimeStringFromTimeObject(props.time, false) : "");
	const [time, setTime] = useState(props.time);
	const [externalError, setExternalError] = useState(undefined);

	const onChangeText = (time) => {
		setInputValue(time);

		let isValid = validTime(time);

		if (isValid) {
			time = { hours: getHours(time), minutes: getMinutes(time) };
		} else {
			time = null;
		}

		let externalError = props.onValueChange(time);

		setTime(time);
		setExternalError(externalError);
	};

	const getHours = (time) => {
		let re = /([01]?[0-9]|2[0-3]):/;
		return re.exec(time)[0].slice(0, -1);
	};

	const getMinutes = (time) => {
		let re = /:[0-5][0-9]/;
		return re.exec(time)[0].substring(1);
	};

	const validTime = (text) => {
		let re = /^(([01]?[0-9]|2[0-3]):[0-5][0-9])$/;
		return re.test(text);
	};

	let isAfter;
	if (props.after !== undefined) {
		isAfter = getIsEndTimeAfterStartTime(props.after, time);
	} else {
		isAfter = true;
	}

	let isValid = Boolean(time);

	let isValueNotModified = props.isValueNotModified ?? time === undefined;

	return (
		<View>
			<TextInput
				style={[styles.textInput, (!isValid && !isValueNotModified) || !isAfter ? styles.invalidTextInput : undefined]}
				placeholder="00:00"
				onChangeText={onChangeText}
				placeholderTextColor={(isValid || isValueNotModified) && isAfter ? placeholderTextColor : invalidPlaceholderTextColor}
				value={inputValue}></TextInput>
			{(isValid || isValueNotModified) && isAfter ? null : <Text style={[styles.textWarning]}>{isAfter ? "¡Escribe una hora válida!" : "La hora final no debe ser menor a la inicial"}</Text>}
		</View>
	);
}
