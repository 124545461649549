import { View, Text, Button, Alert, Dimensions, TouchableOpacity } from "react-native";
import LoggedTemplate from "../LoggedTemplate";
import SectionContainer from "../SectionContainer";
import CalendarPicker from "react-native-calendar-picker";
import { useEffect, useState } from "react";
import { getResponsiveWidth, redHexColor, softLabelColor, styles } from "../../styles/styles";
import TimerInput from "../TimerInput/TimerInput";
import BouncyCheckbox from "react-native-bouncy-checkbox";
import { supabase } from "../../supabase/supabase";
import { getDateFromDateAndTimeObjects, getIsdateBetweenDates, getLocalTimezone, getTimestamptz, timestamptzToDate, timestamptzToJSTimestamp } from "../../helpers/time";
import { getDateAvailableSchedules } from "../../supabase/supabaseController";

import AwesomeAlert from "react-native-awesome-alerts";

export default function AvailableForm(props) {
	let { navigation, route } = props;

	let today = new Date();
	today.setHours(0, 0, 0, 0);

	const [date, setDate] = useState(today);

	const [startTime, setStartTime] = useState(undefined);
	const [isStartDateValueNotModified, setIsStartDateValueNotModified] = useState(undefined);

	const [endTime, setEndTime] = useState(undefined);
	const [isEndDateValueNotModified, setIsEndDateValueNotModified] = useState(undefined);

	const [repeat, setRepeat] = useState(false);

	const [showMessageAlert, setShowMessageAlert] = useState(false);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [showLoadingAlert, setShowLoadingAlert] = useState(false);

	const [errorMessage, setErrorMessage] = useState(undefined);

	const sendForm = async (e) => {
		if (!repeat) {
			if (!startTime || !endTime) {
				setIsStartDateValueNotModified(false);
				setIsEndDateValueNotModified(false);
			} else {
				setShowLoadingAlert(true);
				let availableSchedules = await getDateAvailableSchedules(date);

				let availableStartDate = getDateFromDateAndTimeObjects(date, startTime);
				let availableEndDate = getDateFromDateAndTimeObjects(date, endTime);

				let band;

				availableSchedules.forEach((e) => {
					let betweenStartDate = timestamptzToDate(e.start_date);
					let betweenEndDate = timestamptzToDate(e.end_date);

					if (getIsdateBetweenDates(availableStartDate, betweenStartDate, betweenEndDate) || getIsdateBetweenDates(availableEndDate, betweenStartDate, betweenEndDate)) {
						band = "Ya hay tiempo disponible en esa fecha";
					}
				});

				if (band) {
					setErrorMessage(band);
					setShowErrorAlert(true);

					setShowLoadingAlert(false);
					return;
				}

				let startDate = getTimestamptz(date, startTime);
				let endDate = getTimestamptz(date, endTime);
				let userId = supabase.auth.user().id;

				let row = {
					start_date: startDate,
					end_date: endDate,
					user_id: userId,
				};

				const { data, error } = await supabase.from("available_schedule").insert([row]);

				if (!error) setShowMessageAlert(true);
				else console.log(error);

				setShowLoadingAlert(false);
			}
		}
	};

	const hideAlerts = () => {
		setShowMessageAlert(false);
		setShowErrorAlert(false);
	};

	const restartState = () => {
		setDate(today);

		setStartTime(undefined);
		setIsStartDateValueNotModified(undefined);

		setEndTime(undefined);
		setIsEndDateValueNotModified(undefined);

		setRepeat(false);
	};

	const window = Dimensions.get("window");
	const screen = Dimensions.get("screen");

	const [dimensions, setDimensions] = useState({ window, screen });

	useEffect(() => {
		const subscription = Dimensions.addEventListener("change", ({ window, screen }) => {
			setDimensions({ window, screen });
		});
		return () => subscription?.remove();
	});

	return (
		<>
			<LoggedTemplate
				navigation={navigation}
				route={route}>
				<SectionContainer style={{ backgroundColor: "#efd5e3" }}>
					<View style={[styles.headerDeliveryForm]}>
						<Text style={[styles.headerDeliveryFormText]}>Registra tus horarios disponibles</Text>
						<Text style={[styles.textDeliveryFormText]}>Llena los siguientes datos para indicar tus horarios disponibles dentro de los cuales podrán agendarse entregas.</Text>
					</View>
				</SectionContainer>
				<SectionContainer style={{ flexGrow: 1, paddingTop: 20 }}>
					<View style={styles.formInputContainer}>
						<View>
							<Text style={styles.formInputLabel}>Día disponible</Text>
						</View>
						<View style={styles.calendarContainer}>
							<CalendarPicker // scaleFactor={700}
								width={getResponsiveWidth(95, dimensions.window.width)}
								onDateChange={(date) => {
									setDate(date.toDate());
								}}
								selectedStartDate={date}
								disabledDates={(date) => date < today}
								weekdays={["Dom", "Lun", "Mar", "Mier", "Jue", "Vie", "Sab"]}
								months={["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]}
								previousTitle="Anterior"
								nextTitle="Siguiente"
								selectedDayStyle={{ backgroundColor: "#c6364d" }}
								selectedDayTextColor="white"
							/>
						</View>
					</View>
					<View style={styles.formInputContainer}>
						<View>
							<Text style={styles.formInputLabel}>Empieza a las:</Text>
						</View>
						<View>
							<TimerInput
								onValueChange={(time) => {
									setStartTime(time);
								}}
								isValueNotModified={isStartDateValueNotModified}></TimerInput>
						</View>
					</View>
					<View style={styles.formInputContainer}>
						<View>
							<Text style={styles.formInputLabel}>Termina a las:</Text>
						</View>
						<View>
							<TimerInput
								onValueChange={(time) => {
									setEndTime(time);
								}}
								after={startTime}
								isValueNotModified={isEndDateValueNotModified}></TimerInput>
						</View>
					</View>
					<View style={[styles.formInputContainer, { display: "none" }]}>
						<View>
							<Text style={styles.formInputLabel}>¿Repetir?</Text>
						</View>
						<View>
							<BouncyCheckbox
								fillColor={redHexColor}
								textStyle={{
									textDecorationLine: "none",
									color: "black",
								}}
								text="Repetir"
								onPress={(repeat) => {
									setRepeat(repeat);
								}}
							/>
						</View>
					</View>
					{repeat ? <View style={styles.formInputContainer}></View> : null}
					<View style={styles.formInputContainer}>
						<TouchableOpacity
							style={styles.touchableOpacityButton}
							onPress={sendForm}>
							<View style={[styles.button]}>
								<Text style={[styles.buttonText]}>GUARDAR</Text>
							</View>
						</TouchableOpacity>
					</View>
				</SectionContainer>
			</LoggedTemplate>
			<AwesomeAlert
				show={showMessageAlert}
				showProgress={false}
				title="¡Perfecto!"
				message="Se ha añadido correctamente"
				closeOnTouchOutside={true}
				closeOnHardwareBackPress={true}
				showConfirmButton={true}
				confirmText="Ok"
				// confirmButtonColor="#DD6B55"
				onConfirmPressed={() => {
					hideAlerts();
					restartState();
					navigation.navigate("DeliveryPersonFeed");
				}}
				onDismiss={() => {
					hideAlerts();
					restartState();
					navigation.navigate("DeliveryPersonFeed");
				}}
			/>
			<AwesomeAlert
				show={showErrorAlert}
				showProgress={false}
				title="Error"
				message={errorMessage}
				closeOnTouchOutside={true}
				closeOnHardwareBackPress={true}
				showConfirmButton={true}
				confirmText="Ok"
				confirmButtonColor="#DD6B55"
				onConfirmPressed={hideAlerts}
				onDismiss={hideAlerts}
			/>
			<AwesomeAlert
				show={showLoadingAlert}
				showProgress={true}
				title="Cargando..."
				message="Espere un momento, por favor"
				closeOnTouchOutside={false}
				closeOnHardwareBackPress={false}
			/>
		</>
	);
}
