import { useEffect, useState } from "react";
import { Button, ScrollView, Text, TouchableOpacity, View } from "react-native";
import { styles } from "../../styles/styles";
import BottomBar from "../BottomBar";
import LoggedTemplate from "../LoggedTemplate";
import SectionContainer from "../SectionContainer";
import CalendarDay from "./Subcomponents/CalendarDay";
import CalendarLabel from "./Subcomponents/CalendarLabel";
import { moment } from "../../moment/moment";
import { getDateOfDayOfTheWeek, getMonth } from "../../helpers/time";
import { supabase } from "../../supabase/supabase";
import AwesomeAlert from "react-native-awesome-alerts";
import { useIsFocused } from "@react-navigation/native";
import FontAwesome5 from "@expo/vector-icons/FontAwesome5";
import * as Clipboard from "expo-clipboard";

export default function Calendar(props) {
	let { navigation, route } = props;

	const [date, setDate] = useState(new Date());
	const [mode, setMode] = useState("daily"); //daily, weekly

	const [dateTime, setDateTime] = useState(date.getTime()); //used for loading

	const [showLoadingAlert, setShowLoadingAlert] = useState(false);

	let daysArrowChange;

	if (mode === "daily") daysArrowChange = 1;
	else if (mode === "weekly") daysArrowChange = 7;

	const user = supabase.auth.user();

	const [refreshData, setRefreshData] = useState(false);

	const isFocused = useIsFocused();

	useEffect(() => {
		setRefreshData(!refreshData);
	}, [isFocused, dateTime]);

	const [showMessageAlert, setShowMessageAlert] = useState(false);
	const [showSuccessAlert, setShowSuccessAlert] = useState(false);

	const hideAlerts = () => {
		setShowMessageAlert(false);
		setShowSuccessAlert(false);
	};

	return (
		<>
			<LoggedTemplate
				navigation={navigation}
				route={route}>
				<SectionContainer style={{ backgroundColor: "#c6364d" }}>
					<View style={{ width: "100%", flexDirection: "row", justifyContent: "space-between", marginTop: 60, marginBottom: 10 }}>
						<View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
							<TouchableOpacity
								onPress={() => {
									let dateAux = new Date(date.getTime());
									dateAux.setDate(dateAux.getDate() - daysArrowChange);
									setDate(dateAux);
									setDateTime(dateAux.getTime());
								}}
								style={{ marginLeft: 0, marginRight: 10 }}>
								<FontAwesome5
									name="angle-left"
									size={30}
									color="white"></FontAwesome5>
							</TouchableOpacity>
							<Text style={styles.textMonth}>{getMonth(date, mode)}</Text>
							<TouchableOpacity
								onPress={() => {
									let dateAux = new Date(date.getTime());
									dateAux.setDate(dateAux.getDate() + daysArrowChange);
									setDate(dateAux);
									setDateTime(dateAux.getTime());
								}}
								style={{ marginLeft: 10, marginRight: 10 }}>
								<FontAwesome5
									name="angle-right"
									size={30}
									color="white"></FontAwesome5>
							</TouchableOpacity>
						</View>
						<View style={{ flexDirection: "row" }}>
							<TouchableOpacity
								onPress={() => {
									if (mode === "daily") {
										setMode("weekly");
									} else if (mode === "weekly") {
										setMode("daily");
									}
								}}
								style={{ marginLeft: 10, marginRight: 10, marginTop: 2 }}>
								<FontAwesome5
									name="exchange-alt"
									size={25}
									color="#efd5e3"></FontAwesome5>
							</TouchableOpacity>
							{user ? (
								<TouchableOpacity
									onPress={() => {
										setShowMessageAlert(true);
									}}
									style={{ marginLeft: 20, marginRight: 0, marginTop: 2 }}>
									<FontAwesome5
										name="share"
										size={25}
										color="#efd5e3"></FontAwesome5>
								</TouchableOpacity>
							) : null}
						</View>
					</View>
				</SectionContainer>

				<SectionContainer style={{ flex: 1 }}>
					<ScrollView
						style={{ width: "100%", height: "100%" }}
						contentContainerStyle={{ flexGrow: 1, flexDirection: "row", marginTop: 10, marginBottom: 10 }}
						nestedScrollEnabled={true}>
						<CalendarLabel
						// style={{ width: "10%" }}
						></CalendarLabel>
						<View style={{ flexGrow: 1, flexShrink: 1, flexDirection: "row" }}>
							{mode === "daily" ? (
								<CalendarDay
									date={date}
									setShowLoadingAlert={setShowLoadingAlert}
									refreshData={refreshData}
									// style={{ width: "90%" }}
								></CalendarDay>
							) : (
								(() => {
									let array = [];

									for (let i = 0; i < 7; i++) {
										let dateAux = getDateOfDayOfTheWeek(i, date);

										array.push(
											<CalendarDay
												date={dateAux}
												setShowLoadingAlert={setShowLoadingAlert}
												refreshData={refreshData}
												// style={{ width: 90 / 7 + "%" }}
												key={i}></CalendarDay>
										);
									}

									return array;
								})()
							)}
						</View>
						{user !== null && user.user_metadata.isSeller ? (
							<TouchableOpacity
								color={styles.button.backgroundColor}
								style={[styles.button, styles.openLeftPanelButton, { position: "absolute", bottom: 10, right: 10 }]}
								onPress={() => {
									navigation.navigate("DeliveryForm");
								}}>
								<FontAwesome5
									name="plus"
									color="white"
									size={20}></FontAwesome5>
							</TouchableOpacity>
						) : null}
					</ScrollView>
				</SectionContainer>
			</LoggedTemplate>
			<AwesomeAlert
				show={showSuccessAlert}
				showProgress={false}
				title="¡Link copiado al portapapeles!"
				message=""
				closeOnTouchOutside={true}
				closeOnHardwareBackPress={true}
				showConfirmButton={true}
				confirmText="Okay"
				confirmButtonColor="#c6364d"
				onDismiss={hideAlerts}
				onConfirmPressed={hideAlerts}
				titleStyle={{ textAlign: "center" }}
			/>
			<AwesomeAlert
				show={showMessageAlert}
				showProgress={false}
				title="¡Comparte tu calendario!"
				message="Muéstrale a tus clientes los horarios disponibles para entregas enviándoles siguiente link: https://tso-project.vercel.app/Calendar"
				closeOnTouchOutside={true}
				closeOnHardwareBackPress={true}
				showCancelButton={true}
				showConfirmButton={true}
				cancelText="Cancelar"
				confirmText="Copiar link"
				confirmButtonColor="#c6364d"
				onCancelPressed={hideAlerts}
				onDismiss={hideAlerts}
				onConfirmPressed={async () => {
					await Clipboard.setStringAsync("https://tso-project.vercel.app/Calendar");
					setShowMessageAlert(false);
					setTimeout(() => {
						setShowSuccessAlert(true);
					}, 100);
				}}
				titleStyle={{ textAlign: "center" }}
			/>
			<AwesomeAlert
				show={showLoadingAlert}
				showProgress={true}
				title="Cargando..."
				message="Espere un momento, por favor"
				closeOnTouchOutside={false}
				closeOnHardwareBackPress={false}
			/>
		</>
	);
}
