import { TouchableOpacity } from "react-native";
import FontAwesome5 from "@expo/vector-icons/FontAwesome5";

export default function ArrowButton(props) {
	const { name, func } = props;

	return (
		<TouchableOpacity
			style={[{ flex: 1, alignItems: "center", justifyContent: "center" }]}
			onPress={func}>
			<FontAwesome5
				name={name}
				color="#c6364d"
				size={50}></FontAwesome5>
		</TouchableOpacity>
	);
}
