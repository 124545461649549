import { useIsFocused } from "@react-navigation/native";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { Text, View } from "react-native";
import { areDatesInSameDay } from "../../../helpers/time";
import { styles } from "../../../styles/styles";
import { supabase } from "../../../supabase/supabase";
import { getDateAvailableSchedules, getDateDeliverySchedules } from "../../../supabase/supabaseController";
import AvailableSchedule from "./AvailableSchedule";
import Pedido from "./AvailableSchedule";

export default function AvailableSchedules(props) {
	const { date } = props;

	const [availableSchedules, setAvailableSchedules] = useState();

	const isFocused = useIsFocused();

	const setTodaysAvailableSchedules = async () => {
		setAvailableSchedules(await getDateAvailableSchedules(date));
	};

	useEffect(() => {
		(async () => {
			await setTodaysAvailableSchedules();
		})();
	}, [isFocused, date]);

	let text;

	if (areDatesInSameDay(date, new Date())) {
		text = "No hay horarios libres para para hoy";
	} else if (date <= new Date()) {
		text = `No hay horarios libres de ${moment(date).fromNow()}`;
	} else {
		text = `No hay horarios libres ${moment(date).fromNow()}`;
	}

	return (
		<View style={styles.pedidosContainer}>
			{availableSchedules !== undefined ? (
				availableSchedules.length > 0 ? (
					availableSchedules.map((availableSchedule, index) => (
						<AvailableSchedule
							key={index}
							availableSchedule={availableSchedule}
							updateFunction={setTodaysAvailableSchedules}
							{...props}></AvailableSchedule>
					))
				) : (
					<Text style={styles.noPedidos}>{text}</Text>
				)
			) : (
				<Text>Cargando...</Text>
			)}
		</View>
	);
}
