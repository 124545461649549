import { Text, View } from "react-native";
import { calendarBorderColor, calendarLabelColor, styles } from "../../../styles/styles";

export default function CalendarLabel(props) {
	styles;
	return (
		<View style={[{}, props.style]}>
			<View style={{ height: 50 }}></View>
			<View style={{ flexGrow: 1 }}>
				{(() => {
					let array = [];
					for (let i = 0; i < 24; i++) {
						const boxHeigth = 100 / 24;

						array.push(
							<View
								style={{ height: `${boxHeigth}%`, flexDirection: "row" }}
								key={i}>
								{i > 0 ? <Text style={{ marginRight: 5, position: "relative", top: -8, color: calendarLabelColor }}>{`${i}:00`}</Text> : null}
								<View style={{ flexGrow: 1, minWidth: 5, borderTopWidth: 1, borderRightWidth: 1, borderColor: calendarBorderColor }}></View>
							</View>
						);
					}
					return array;
				})()}
			</View>
		</View>
	);
}
