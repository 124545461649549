import { useState } from "react";
import { ScrollView, Button, Text, TextInput, View, TouchableOpacity } from "react-native";
import AwesomeAlert from "react-native-awesome-alerts";
import BouncyCheckbox from "react-native-bouncy-checkbox";
import { placeholderTextColor, redHexColor, styles } from "../../styles/styles";
import { supabase } from "../../supabase/supabase";
import CardContainer from "../CardContainer";
import RadioButtonGroup, { RadioButtonItem } from "expo-radio-button";

export default function Register() {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [verifiedPassword, setVerifiedPassword] = useState("");
	const [isDeliveryPerson, setIsDeliveryPerson] = useState(false);
	const [isSeller, setIsSeller] = useState(false);

	const [role, setRole] = useState("Delivery person");

	const [showPassword, setShowPassword] = useState(false);
	const [showVerifiedPassword, setShowVerifiedPassword] = useState(false);

	const [error, setError] = useState(null);
	const [registered, setRegistered] = useState(false);

	const showError = (error) => {
		setError(error);
	};

	const signUp = async (e) => {
		setShowLoadingAlert(true);

		let user, session, error, data;

		({ user, session, error } = await supabase.auth.signUp(
			{
				email,
				password,
			},
			{
				data: {
					name,
					isDeliveryPerson: role === "Delivery person",
					isSeller: role === "Seller",
				},
			}
		));

		if (error) {
			showError(error);
			setShowLoadingAlert(false);
			return;
		}

		({ data, error } = await supabase.from("users_data").insert([{ user_id: user.id, name, is_delivery_person: role === "Delivery person", is_seller: role === "Seller" }]));

		if (error) showError(error);
		else setRegistered(true);

		setError(null);
		setShowLoadingAlert(false);
	};

	const [showLoadingAlert, setShowLoadingAlert] = useState(false);

	let isLoginButtonDisabled = password !== verifiedPassword || name === "" || email === "" || password === "";

	return (
		<>
			<CardContainer>
				<View style={styles.titleContainer}>
					<Text style={[styles.title, { fontFamily: "PlayfairDisplay-Bold" }]}>Nuevo ¿hum?</Text>
				</View>

				{registered ? (
					<View style={styles.successContainer}>
						<Text style={[styles.successText, { fontFamily: "Raleway-Regular" }]}>Te has registrado correctamente. Por favor, verifica tu cuenta a través del link enviado a tu correo.</Text>
					</View>
				) : (
					<></>
				)}

				{error ? (
					<View style={styles.errorContainer}>
						<Text style={styles.errorText}>{error.message}</Text>
					</View>
				) : (
					<></>
				)}

				<View style={styles.formInputContainer}>
					<View>
						<Text style={[styles.inputLabel, { fontFamily: "Raleway-Medium" }]}>Tu nombre</Text>
					</View>
					<View>
						<TextInput
							style={[styles.textInput, { fontFamily: "Raleway-Regular" }]}
							placeholder="Nombre de villano"
							onChangeText={(name) => setName(name)}
							placeholderTextColor={placeholderTextColor}></TextInput>
					</View>
				</View>

				<View style={styles.formInputContainer}>
					<View>
						<Text style={[styles.inputLabel, { fontFamily: "Raleway-Medium" }]}>Tu correo electrónico</Text>
					</View>
					<View>
						<TextInput
							style={[styles.textInput, { fontFamily: "Raleway-Regular" }]}
							placeholder="malvado@correo.com"
							onChangeText={(email) => setEmail(email)}
							autoComplete="email"
							placeholderTextColor={placeholderTextColor}></TextInput>
					</View>
				</View>

				<View style={styles.formInputContainer}>
					<View>
						<Text style={[styles.inputLabel, { fontFamily: "Raleway-Medium" }]}>Tu contraseña</Text>
					</View>
					<View>
						<TextInput
							style={[styles.textInput, { fontFamily: "Raleway-Regular" }]}
							placeholder="Contraseña ultra-secreta"
							secureTextEntry={!showPassword}
							onChangeText={(password) => setPassword(password)}
							placeholderTextColor={placeholderTextColor}></TextInput>
					</View>
					<View style={styles.bouncyCheckBox}>
						<BouncyCheckbox
							size={15}
							fillColor={redHexColor}
							textStyle={{
								textDecorationLine: "none",
								marginLeft: -8,
								fontSize: 12,
								fontFamily: "Raleway-Italic",
							}}
							text="Mostrar contraseña"
							onPress={(showPassword) => {
								setShowPassword(showPassword);
							}}
						/>
					</View>
				</View>

				<View style={styles.formInputContainer}>
					<View>
						<Text style={[styles.inputLabel, { fontFamily: "Raleway-Medium" }]}>Confirma tu contraseña</Text>
					</View>
					<View>
						<TextInput
							style={[styles.textInput, { fontFamily: "Raleway-Regular" }]}
							placeholder="Contraseña ultra-secreta"
							secureTextEntry={!showVerifiedPassword}
							onChangeText={(verifiedPassword) => setVerifiedPassword(verifiedPassword)}
							placeholderTextColor={placeholderTextColor}></TextInput>
					</View>
					<View style={styles.bouncyCheckBox}>
						<BouncyCheckbox
							size={15}
							fillColor={redHexColor}
							textStyle={{
								textDecorationLine: "none",
								marginLeft: -8,
								fontSize: 12,
								fontFamily: "Raleway-Italic",
							}}
							text="Mostrar contraseña"
							onPress={(showVerifiedPassword) => {
								setShowVerifiedPassword(showVerifiedPassword);
							}}
						/>
					</View>
				</View>

				{password !== verifiedPassword ? (
					<View style={styles.errorContainer}>
						<Text style={[styles.errorText, { fontFamily: "Raleway-Regular" }]}>Las contraseñas no coinciden</Text>
					</View>
				) : (
					<></>
				)}

				<View style={styles.formInputContainer}>
					<Text style={styles.inputLabel}>Rol</Text>
					<View>
						<RadioButtonGroup
							selected={role}
							onSelected={(role) => setRole(role)}
							radioBackground={redHexColor}>
							<RadioButtonItem
								value="Delivery person"
								label={<Text style={{ color: "gray", marginLeft: 8 }}>Repartidor</Text>}
								style={{ marginLeft: 8, marginBottom: 4, marginTop: 4 }}
							/>
							<RadioButtonItem
								value="Seller"
								label={<Text style={{ color: "gray", marginLeft: 8 }}>Vendedor</Text>}
								style={{ marginLeft: 8, marginBottom: 4, marginTop: 4 }}
							/>
						</RadioButtonGroup>
					</View>
				</View>

				<View style={styles.buttonContainer}>
					<TouchableOpacity
						style={styles.touchableOpacityButton}
						onPress={signUp}
						disabled={isLoginButtonDisabled}>
						<View style={[styles.button, isLoginButtonDisabled ? styles.buttonDisabled : undefined]}>
							<Text style={[styles.buttonText, isLoginButtonDisabled ? styles.buttonDisabledText : undefined]}>CREAR CUENTA</Text>
						</View>
					</TouchableOpacity>
				</View>
			</CardContainer>
			<AwesomeAlert
				show={showLoadingAlert}
				showProgress={true}
				title="Cargando..."
				message="Espere un momento, por favor"
				closeOnTouchOutside={false}
				closeOnHardwareBackPress={false}
			/>
		</>
	);
}
