import { useState } from "react";
import { Button, Platform, View, Text, TouchableOpacity } from "react-native";
import { redHexColor, styles } from "../styles/styles";
import { supabase } from "../supabase/supabase";
import FontAwesome5 from "@expo/vector-icons/FontAwesome5";
import { removeExpoPushToken } from "../supabase/supabaseController";
import { getExpoPushToken } from "../helpers/notifications";

export default function LeftPannel(props) {
	let { navigation, route } = props;

	const WIDTH = "50%";
	const CLOSE_BUTTON_SIZE = 50;

	let { visible, setVisible } = props;

	const signOut = async (e) => {
		navigation.navigate("Home");

		const expoPushToken = await getExpoPushToken();

		if (expoPushToken !== undefined) {
			await removeExpoPushToken(supabase.auth.user(), expoPushToken);
		}

		const { error } = await supabase.auth.signOut();
	};

	const hide = (e) => {
		setVisible(false);
	};

	return (
		<>
			<View style={[styles.leftPannel, { width: WIDTH, left: visible ? 0 : `-${WIDTH}` }]}>
				<Text style={styles.textLeftPannel}>¿Deseas cerrar sesión?</Text>
				<TouchableOpacity
					style={styles.touchableOpacityButton}
					onPress={signOut}>
					<View style={[styles.buttonLogOut]}>
						<Text style={[styles.buttonText]}>CONFIRMAR</Text>
					</View>
				</TouchableOpacity>
			</View>
			<View style={{ display: visible ? "flex" : "none", position: "absolute", left: visible ? WIDTH /*`calc( ${WIDTH} - ${CLOSE_BUTTON_SIZE / 2}px )`*/ : 0, width: CLOSE_BUTTON_SIZE }}>
				<TouchableOpacity
					onPress={hide}
					style={[styles.buttonClose, {width: CLOSE_BUTTON_SIZE, height: CLOSE_BUTTON_SIZE,}]}>
					<FontAwesome5
						name="times"
						color={redHexColor}
						size={25}></FontAwesome5>
				</TouchableOpacity>
			</View>
		</>
	);
}
