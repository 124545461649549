import { useEffect, useState } from "react";
import { Dimensions, View } from "react-native";
import { getMainContainerPadding, getMainContainerPaddingObject, styles } from "../styles/styles";

export default function SectionContainer(props) {
	const window = Dimensions.get("window");
	const screen = Dimensions.get("screen");

	const [dimensions, setDimensions] = useState({ window, screen });

	useEffect(() => {
		const subscription = Dimensions.addEventListener("change", ({ window, screen }) => {
			setDimensions({ window, screen });
		});
		return () => subscription?.remove();
	});

	return <View style={[styles.sectionContainer, props.style, getMainContainerPaddingObject(dimensions.window.width)]}>{props.children}</View>;
}
