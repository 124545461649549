import { useEffect, useState } from "react";
import { View, ScrollView, Image, Dimensions } from "react-native";
import { getMainContainerPaddingObject, styles } from "../styles/styles";
import gato from "../../assets/img/black-cat.png";

export default function CardContainer(props) {
	const window = Dimensions.get("window");
	const screen = Dimensions.get("screen");

	const [dimensions, setDimensions] = useState({ window, screen });

	useEffect(() => {
		const subscription = Dimensions.addEventListener("change", ({ window, screen }) => {
			setDimensions({ window, screen });
		});
		return () => subscription?.remove();
	});

	return (
		<ScrollView contentContainerStyle={[{ flexGrow: 1 }]}>
			<View style={[styles.sectionContainer, styles.red, styles.centerContainer, styles.cardContainer, { justifyContent: "center" }, getMainContainerPaddingObject(dimensions.window.width)]}>
				<View>
					<Image
						source={gato}
						style={[styles.imageCat]}
					/>
				</View>
				<View style={styles.card}>{props.children}</View>
			</View>
		</ScrollView>
	);
}
