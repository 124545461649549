import { Text, TouchableOpacity, View } from "react-native";
import SectionContainer from "./SectionContainer";
import FontAwesome5 from "@expo/vector-icons/FontAwesome5";
import { styles } from "../styles/styles";
import { supabase } from "../supabase/supabase";
import ArrowButton from "./ArrowButton";

export default function HeaderSectionContainer(props) {
	const { setLeftPannelVisible, children, leftButtonFunction, rightButtonFunction } = props;

	return (
		<SectionContainer style={{ backgroundColor: "#efd5e3" }}>
			<View style={{ flexDirection: "row" }}>
				<View>
					<ArrowButton
						name="angle-left"
						func={leftButtonFunction}></ArrowButton>
				</View>
				<View style={styles.headerHome}>
					<TouchableOpacity
						style={[styles.button, styles.openLeftPanelButton]}
						color={styles.button.backgroundColor}
						onPress={() => {
							setLeftPannelVisible(true);
						}}>
						<FontAwesome5
							name="cat"
							size={30}></FontAwesome5>
					</TouchableOpacity>
					{children}
				</View>
				<View>
					<ArrowButton
						name="angle-right"
						func={rightButtonFunction}></ArrowButton>
				</View>
			</View>
		</SectionContainer>
	);
}
