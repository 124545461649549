import { Dimensions, StyleSheet } from "react-native";

export const redHexColor = "#c6364d";
export const borderRadius = 20;
export const placeholderTextColor = "grey";
export const invalidPlaceholderTextColor = "#c4c4c4";
export const calendarBorderColor = "#C8C8C8";
export const calendarLabelColor = "#696969";
export const softLabelColor = "#585858";

export const getIsMobile = (width) => width < 400;

export const getMainContainerPadding = (width) => (getIsMobile(width) ? 20 : "25%");

export const getMainContainerPaddingObject = (width) => {
	let mainContainerPadding = getMainContainerPadding(width);

	return {
		paddingLeft: mainContainerPadding,
		paddingRight: mainContainerPadding,
	};
};

export const getResponsiveWidth = (width, screenWidth) => {
	const relativeScreenWidth = screenWidth * (width / 100);

	return getIsMobile(screenWidth) ? relativeScreenWidth - 40 : relativeScreenWidth - (relativeScreenWidth * 0.4 + 40);
};

export const styles = StyleSheet.create({
	red: {
		backgroundColor: redHexColor,
	},
	white: {
		backgroundColor: "white",
	},
	sectionContainer: {
		width: "100%",
		overflow: "visible",

		backgroundColor: "white",
	},
	centerContainer: {
		flex: 1,
		alignItems: "center",
		// justifyContent: "center",
	},
	cardContainer: {
		paddingBottom: 20,
		paddingTop: 20,
	},
	card: {
		backgroundColor: "#ffffff",
		width: "100%",
		padding: 30,
		borderRadius,
	},
	touchableOpacityButton: {
		width: "100%",
		alignItems: "center",
	},

	button: {
		backgroundColor: redHexColor,
		borderRadius,
		width: "100%",
		borderRadius: 20,
		alignItems: "center",
		justifyContent: "center",
		padding: 10,
	},
	buttonDisabled: {
		backgroundColor: "#dfdfdf",
	},
	buttonText: {
		fontSize: 12,
		fontFamily: "Raleway-Bold",
		color: "white",
		letterSpacing: 2,
	},
	buttonDisabledText: {
		color: "#a4a8af",
	},
	buttonContainer: {
		margin: 5,
	},
	titleContainer: {
		flex: 1,
		backgroundColor: "#fff",
		alignItems: "center",
		justifyContent: "center",
	},
	title: {
		marginBottom: 15,
		fontSize: 22,
	},
	errorContainer: {
		padding: 10,
		marginBottom: 15,
		backgroundColor: "red",
		borderRadius: 10,
	},
	errorText: {
		color: "white",
	},
	successContainer: {
		padding: 10,
		marginBottom: 15,
		backgroundColor: "#4BB543",
		borderRadius: 10,
	},
	successText: {
		color: "white",
	},
	textInput: {
		borderRadius: 15,
		padding: 10,
		backgroundColor: "#eceff1",
		fontFamily: "Raleway-Regular",
	},
	invalidTextInput: {
		backgroundColor: redHexColor,
		color: "white",
	},
	formInputContainer: {
		marginBottom: 20,
	},
	inputLabel: {
		fontSize: 16,
		marginBottom: 5,
	},
	formInputLabel: {
		fontSize: 16,
		marginBottom: 5,
		color: redHexColor,
		fontFamily: "Raleway-Bold",
	},
	headerHome: {
		flex: 1,
		paddingTop: 30,
		paddingLeft: 30,
		paddingRight: 30,
		paddingBottom: 20,
	},
	headerHomeText1: {
		fontSize: 30,
		fontFamily: "PlayfairDisplay-Bold",
		paddingTop: 20,
	},
	headerHomeText2: {
		fontSize: 25,
		color: redHexColor,
		fontFamily: "PlayfairDisplay-Bold",
	},
	headerHomeText3: {
		fontSize: 18,
		color: "black",
		fontFamily: "Raleway-Medium",
		paddingTop: 20,
	},
	pedidosContainer: {
		width: "100%",
		paddingTop: 20,
		paddingLeft: 0,
		paddingRight: 0,
		paddingBottom: 20,
	},
	pedidoContainer: {
		width: "100%",
		borderColor: "#c9c9c9",
		borderWidth: 1,
		marginBottom: 10,
		borderRadius: 5,
		padding: 10,
		flexDirection: "row",
	},
	deliveryPackageDescription: {
		fontSize: 18,
		fontFamily: "Raleway-Bold",
	},
	deliveryClient: { 
		fontSize: 16,
		fontFamily: "Raleway-Regular",
	},
	deliveryPlace: { 
		fontSize: 14, 
		color: "#c92626",
		fontFamily: "Raleway-Regular",
	},
	deliveryTime: { 
		fontSize: 12, 
		color: "#5e5e5e",
		fontFamily: "Raleway-Regular",
	},
	logoutButton: {
		width: "50%",
	},
	openLeftPanelButton: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: 60,
		width: 60,
		borderRadius: 50,
	},
	trashButton: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: 40,
		width: 40,
		borderRadius: 40,
	},
	bottomButton: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "25%",
		margin: 10,
		color: "rgb(198, 54, 77)",
		// backgroundColor: "grey",
	},
	calendarContainer: {
		// backgroundColor: "white",
		paddingTop: 20,
		paddingBottom: 20,
		borderRadius: 50,
	},
	inputPrefix: {
		marginLeft: 5,
		marginRight: 5,
		color: placeholderTextColor,
	},
	calendarBox: {
		position: "absolute",
		width: "100%",
		outlineStyle: "solid",
		outlineWidth: 1,
		borderColor: "black",
	},
	imageCat: {
		resizeMode: "contain",
		height: 200,
		width: 200,
	},
	bouncyCheckBox: {
		paddingTop: 8,
		paddingLeft: 8,
	},
	noPedidos: {
		fontSize: 16,
		color: "#383838",
		fontFamily: "Raleway-Italic",
		padding: 30,
		textAlign: "center",
	},
	barNav: {
		height: "9%", 
		display: "flex", 
		flexDirection: "row", 
		justifyContent: "space-around", 
		marginTop: 1,
		shadowColor: "#000",
		shadowOffset: {
			width: 3,
			height: 0,
		},
		shadowOpacity: 0.2,
		shadowRadius: 5,
		elevation: 7,
	},
	availableSchedule: {
		fontSize: 14,
		fontFamily: "Raleway-Regular",
	},
	availableHour: {
		fontSize: 22, 
		color: "#c92626",
		fontFamily: "Raleway-Bold",
	},
	headerDeliveryForm: {
		flex: 1,
		paddingTop: 0,
		paddingLeft: 0,
		paddingRight: 0,
		paddingBottom: 25,
	},
	headerDeliveryFormText: {
		fontSize: 30,
		fontFamily: "PlayfairDisplay-Bold",
		paddingTop: 20,
		marginBottom: 10,
		color: redHexColor,
	},
	textDeliveryFormText: {
		fontFamily: "Raleway-Regular",
		color: softLabelColor
	},
	textDropDownPicker: {
		fontFamily: "Raleway-Regular",
	},
	leftPannel: {
		height: "100%", 
		position: "absolute", 
		backgroundColor: "white", 
		shadowColor: "#000",
		shadowOffset: {
			width: 3,
			height: 0,
		},
		shadowOpacity: 0.2,
		shadowRadius: 5,
		elevation: 7,
	},
	buttonLogOut: {
		marginTop: 20,
		backgroundColor: redHexColor,
		borderRadius,
		width: "90%",
		borderRadius: 20,
		alignItems: "center",
		justifyContent: "center",
		padding: 10,
	},
	buttonClose: {
		display: "flex", 
		alignItems: "center", 
		justifyContent: "center", 
		borderRadius: 50, 
		border: "2px solid #c6364d", 
		backgroundColor: "white",
	},
	textLeftPannel: {
		fontSize: 14,
		color: "black",
		fontFamily: "Raleway-Medium",
		paddingTop: 20,
		marginBottom: -10,
		textAlign: 'center',
	},
	textWarning: {
		paddingTop: 10,
		fontSize: 14,
		color: "gray",
		fontFamily: "Raleway-Italic",
	},
	textMonth: {
		color: "white", 
		fontFamily: "Raleway-Bold",
		fontSize: 20,
		textAlign: 'center',
	},
	errorPage: {
		fontSize: 30,
		fontFamily: "PlayfairDisplay-Bold",
		padding: 20,
		marginBottom: 10,
	}
});
