import { Image, Text, TouchableOpacity, View } from "react-native";
import { timestamptzToDate } from "../../../helpers/time";
import { invalidPlaceholderTextColor, styles } from "../../../styles/styles";
import { supabase } from "../../../supabase/supabase";
import FontAwesome5 from "@expo/vector-icons/FontAwesome5";
import { productImagesURL } from "../../../supabase/credentials";

export default function Pedido(props) {
	const { updateFunction, delivery, setShowMessageAlert, setShowErrorAlert, setShowLoadingAlert, setErrorMessage, navigation } = props;

	const user = supabase.auth.user();

	// For the time now
	Date.prototype.timeNow = function () {
		return (this.getHours() < 10 ? "0" : "") + this.getHours() + ":" + (this.getMinutes() < 10 ? "0" : "") + this.getMinutes() + "hrs";
	};

	let date = timestamptzToDate(delivery.time);

	const deleteDelivery = async () => {
		setShowLoadingAlert(true);

		let data, error;

		({ data, error } = await supabase.from("delivery_schedule").delete().eq("id", delivery.id));

		({ data, error } = await supabase.storage.from("products").remove(["product" + delivery.id]));

		if (!error) {
			await updateFunction();

			setShowMessageAlert(true);
		} else {
			setErrorMessage("No se ha podido eliminar el registro");
			setShowErrorAlert(true);
		}

		setShowLoadingAlert(false);
	};

	return (
		<TouchableOpacity
			onPress={() => {
				navigation.navigate("DeliveryForm", {
					delivery,
				});
			}}
			disabled={!user.user_metadata.isSeller}>
			<View style={styles.pedidoContainer}>
				<View style={{ alignItems: "center", justifyContent: "center", marginHorizontal: 5 }}>
					<Image
						style={{
							width: 100,
							height: 100,
							marginRight: 5,
							borderRadius: 10,
						}}
						source={{
							uri: productImagesURL + delivery.id,
						}}
					/>
				</View>
				<View style={{ flex: 1 }}>
					<View>
						<Text style={styles.deliveryPackageDescription}>{delivery.package_description}</Text>
					</View>
					<View>
						<Text style={styles.deliveryClient}>{delivery.client}</Text>
					</View>
					<View>
						<Text style={styles.deliveryPlace}>{delivery.place}</Text>
					</View>
					<View>
						<Text style={styles.deliveryTime}>{date.timeNow()}</Text>
					</View>
				</View>
				{user.user_metadata.isSeller ? (
					<View style={{ justifyContent: "center" }}>
						<TouchableOpacity
							style={[styles.button, styles.trashButton, { backgroundColor: invalidPlaceholderTextColor }]}
							color={styles.button.backgroundColor}
							onPress={deleteDelivery}>
							<FontAwesome5
								name="trash"
								size={20}></FontAwesome5>
						</TouchableOpacity>
					</View>
				) : null}
			</View>
		</TouchableOpacity>
	);
}
