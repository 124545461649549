import { useState } from "react";
import { StyleSheet, Text, View, Button, ScrollView, Platform, TouchableOpacity } from "react-native";
import AwesomeAlert from "react-native-awesome-alerts";
import { styles } from "../../styles/styles";
import { supabase } from "../../supabase/supabase";
import BottomBar from "../BottomBar";
import LoggedTemplate from "../LoggedTemplate";
import SectionContainer from "../SectionContainer";
import Pedidos from "./subcomponents/Pedidos";
import FontAwesome5 from "@expo/vector-icons/FontAwesome5";
import HeaderSectionContainer from "../HeaderSectionContainer";
import { areDatesInSameDay, dateMinusDays, datePlusDays } from "../../helpers/time";
import moment from "moment/moment";

export default function Home(props) {
	let { navigation, route } = props;

	const [date, setDate] = useState(new Date());

	const [leftPannelVisible, setLeftPannelVisible] = useState(false);

	const [showMessageAlert, setShowMessageAlert] = useState(false);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [showLoadingAlert, setShowLoadingAlert] = useState(false);

	const [errorMessage, setErrorMessage] = useState("");

	const hideAlerts = () => {
		setShowMessageAlert(false);
		setShowErrorAlert(false);
	};

	const restartState = () => {};

	let title;

	if (areDatesInSameDay(date, new Date())) {
		title = "Tus pedidos de hoy";
	} else if (date <= new Date()) {
		title = `Tus pedidos de ${moment(date).fromNow()}`;
	} else {
		title = `Tus pedidos ${moment(date).fromNow()}`;
	}

	return (
		<>
			<LoggedTemplate
				navigation={navigation}
				route={route}
				leftPannelVisible={leftPannelVisible}
				setLeftPannelVisible={setLeftPannelVisible}>
				<HeaderSectionContainer
					setLeftPannelVisible={setLeftPannelVisible}
					leftButtonFunction={() => {
						setDate(dateMinusDays(date, 1));
					}}
					rightButtonFunction={() => {
						setDate(datePlusDays(date, 1));
					}}>
					<Text style={styles.headerHomeText1}>{title}</Text>
					<Text style={styles.headerHomeText2}>{supabase.auth.user().user_metadata.name}</Text>
					<Text style={styles.headerHomeText3}>Pedidos por entregar</Text>
				</HeaderSectionContainer>
				<SectionContainer style={{ flexGrow: 1 }}>
					<Pedidos
						date={date}
						setShowMessageAlert={setShowMessageAlert}
						setShowErrorAlert={setShowErrorAlert}
						setShowLoadingAlert={setShowLoadingAlert}
						setErrorMessage={setErrorMessage}
						navigation={navigation}></Pedidos>
				</SectionContainer>
			</LoggedTemplate>
			<AwesomeAlert
				show={showMessageAlert}
				showProgress={false}
				title="¡Perfecto!"
				message="Se ha eliminado correctamente"
				closeOnTouchOutside={true}
				closeOnHardwareBackPress={true}
				showConfirmButton={true}
				confirmText="Ok"
				// confirmButtonColor="#DD6B55"
				onConfirmPressed={() => {
					hideAlerts();
					restartState();
					navigation.navigate("Home");
				}}
				onDismiss={() => {
					hideAlerts();
					restartState();
					navigation.navigate("Home");
				}}
			/>
			<AwesomeAlert
				show={showErrorAlert}
				showProgress={false}
				title="Error"
				message={errorMessage}
				closeOnTouchOutside={true}
				closeOnHardwareBackPress={true}
				showConfirmButton={true}
				confirmText="Ok"
				confirmButtonColor="#DD6B55"
				onConfirmPressed={hideAlerts}
				onDismiss={hideAlerts}
			/>
			<AwesomeAlert
				show={showLoadingAlert}
				showProgress={true}
				title="Cargando..."
				message="Espere un momento, por favor"
				closeOnTouchOutside={false}
				closeOnHardwareBackPress={false}
			/>
		</>
	);
}
