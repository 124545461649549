import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import { Platform } from "react-native";

export const getExpoPushToken = async () => {
	let token;

	if (Device.isDevice) {
		const { status: existingStatus } = await Notifications.getPermissionsAsync();
		let finalStatus = existingStatus;

		if (existingStatus !== "granted") {
			const { status } = await Notifications.requestPermissionsAsync();
			finalStatus = status;
		}

		if (finalStatus !== "granted" || Platform.OS === "web") {
			// alert("Failed to get push token for push notification!");
			return;
		}

		token = (await Notifications.getExpoPushTokenAsync()).data;
	} else {
		alert("Must use physical device for Push Notifications");
	}

	if (Platform.OS === "android") {
		await Notifications.setNotificationChannelAsync("default", {
			name: "default",
			importance: Notifications.AndroidImportance.MAX,
			vibrationPattern: [0, 250, 250, 250],
			lightColor: "#FF231F7C",
			sound: "notification_sound.wav",
		});
	}

	return token;
};
