import { createClient } from "@supabase/supabase-js";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { supabase_url, supabase_anon_key } from "./credentials";
import { Platform } from "react-native";

const options = {
	localStorage: AsyncStorage,
	storage: AsyncStorage,
	autoRefreshToken: true,
	persistSession: true,
	detectSessionInUrl: false,
	auth: {
		localStorage: AsyncStorage,
		storage: AsyncStorage,
		autoRefreshToken: true,
		persistSession: true,
		detectSessionInUrl: false,
	},
};

// export const client = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY);
export const supabase = createClient(supabase_url, supabase_anon_key, options);
