import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useEffect, useRef, useState } from "react";
import Home from "./src/components/Home/Home";
import Login from "./src/components/Login/Login";
import Register from "./src/components/Register/Register";
import { supabase } from "./src/supabase/supabase";
import { Platform, Text, View } from "react-native";
import Calendar from "./src/components/Calendar/Calendar";
import DeliveryForm from "./src/components/DeliveryForm/DeliveryForm";
import AvailableForm from "./src/components/AvailableForm/AvailableForm";
import DeliveryPersonFeed from "./src/components/DeliveryPersonFeed/DeliveryPersonFeed";
import * as Linking from "expo-linking";
import Error404 from "./src/components/Error404/Error404";
import { useFonts } from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import * as Notifications from "expo-notifications";
import "react-native-url-polyfill/auto";
import { addExpoPushToken } from "./src/supabase/supabaseController";
import { getExpoPushToken } from "./src/helpers/notifications";
import { LogBox } from "react-native";

const prefix = Linking.createURL("/");
const Stack = createNativeStackNavigator();

SplashScreen.preventAutoHideAsync();

let customFonts = {
	"Raleway-Bold": require("./assets/fonts/Raleway-Bold.ttf"),
	"Raleway-Regular": require("./assets/fonts/Raleway-Regular.ttf"),
	"Raleway-Medium": require("./assets/fonts/Raleway-Medium.ttf"),
	"Raleway-Italic": require("./assets/fonts/Raleway-Italic.ttf"),
	"PlayfairDisplay-Bold": require("./assets/fonts/PlayfairDisplay-Bold.ttf"),
	"PlayfairDisplay-Regular": require("./assets/fonts/PlayfairDisplay-Regular.ttf"),
};

Notifications.setNotificationHandler({
	handleNotification: async () => ({
		shouldShowAlert: true,
		shouldPlaySound: false,
		shouldSetBadge: false,
	}),
});

LogBox.ignoreLogs(["new NativeEventEmitter"]); // Ignore log notification by message
LogBox.ignoreAllLogs(); //Ignore all log notifications

export default function App() {
	const [user, setUser] = useState(supabase.auth.user());

	const [expoPushToken, setExpoPushToken] = useState(undefined);
	const [notification, setNotification] = useState(false);
	const notificationListener = useRef();
	const responseListener = useRef();

	supabase.auth.onAuthStateChange(() => {
		setUser(supabase.auth.user());
	});

	const [fontsLoaded] = useFonts(customFonts);

	useEffect(() => {
		(async () => {
			if (fontsLoaded) {
				await SplashScreen.hideAsync();
			}
		})();
	}, [fontsLoaded]);

	useEffect(() => {
		getExpoPushToken().then((token) => setExpoPushToken(token));

		notificationListener.current = Notifications.addNotificationReceivedListener((notification) => {
			setNotification(notification);
		});

		responseListener.current = Notifications.addNotificationResponseReceivedListener((response) => {
			console.log(response);
		});

		return () => {
			Notifications.removeNotificationSubscription(notificationListener.current);
			Notifications.removeNotificationSubscription(responseListener.current);
		};
	}, []);

	useEffect(() => {
		(async () => {
			if (user !== null && expoPushToken !== undefined) {
				await addExpoPushToken(user, expoPushToken);
			}
		})();
	}, [user, expoPushToken]);

	if (!fontsLoaded) return null;

	// (async () => {
	// 	const { data, error } = await supabase.auth.update({
	// 		data: {
	// 			name: "Israel",
	// 			isDeliveryPerson: false,
	// 			isSeller: true,
	// 		},
	// 	});
	// })();

	const linking = {
		prefixes: [prefix, "https://tso-project.vercel.app"],
		config: {
			screens: {
				Home: "Home",
				Calendar: "Calendar",
				DeliveryForm: "DeliveryForm",
				DeliveryPersonFeed: "DeliveryPersonFeed",
				AvailableForm: "AvailableForm",
				Register: "Register",
				Error404: "*",
			},
		},
	};

	return (
		<NavigationContainer
			linking={linking}
			fallback={<Text>Loading...</Text>}>
			<Stack.Navigator
				initialRouteName="Home"
				// screenOptions={{
				// 	headerShown: false,
				// }}
				options={{
					cardStyle: { flex: 1 },
				}}>
				{user !== null ? (
					<>
						<Stack.Screen
							name="Home"
							component={Home}
							options={{
								title: "",
								headerTintColor: "#c6364d",
								headerBackVisible: false,
							}}
						/>
						<Stack.Screen
							name="DeliveryForm"
							component={DeliveryForm}
							options={{
								title: "Nueva entrega",
								headerTintColor: "#c6364d",
								headerTitleStyle: {
									fontFamily: "Raleway-Bold",
								},
							}}
						/>
						<Stack.Screen
							name="DeliveryPersonFeed"
							component={DeliveryPersonFeed}
							options={{
								title: "",
								headerTintColor: "#c6364d",
								headerTitleStyle: {
									fontFamily: "Raleway-Bold",
								},
								headerBackVisible: false,
							}}
						/>
						<Stack.Screen
							name="AvailableForm"
							component={AvailableForm}
							options={{
								title: "Nuevo horario",
								headerTintColor: "#c6364d",
								headerTitleStyle: {
									fontFamily: "Raleway-Bold",
								},
							}}
						/>
					</>
				) : (
					<>
						<Stack.Screen
							name="Home"
							component={Login}
							options={{
								title: "Bienvenido, diablillo...",
								headerTintColor: "#c6364d",
								headerTitleStyle: {
									fontFamily: "Raleway-Bold",
								},
							}}
						/>
						<Stack.Screen
							name="Register"
							component={Register}
							options={{
								title: "Registro",
								headerTintColor: "#c6364d",
								headerTitleStyle: {
									fontFamily: "Raleway-Bold",
								},
							}}
						/>
					</>
				)}
				<Stack.Screen
					name="Calendar"
					component={Calendar}
					options={{
						title: "Calendario",
						headerTintColor: "#c6364d",
						headerTitleStyle: {
							fontFamily: "Raleway-Bold",
						},
						headerShown: false
					}}
				/>
				<Stack.Screen
					name="Error404"
					component={Error404}
					options={{
						title: "Error 404",
						headerTintColor: "#c6364d",
						headerTitleStyle: {
							fontFamily: "Raleway-Bold",
						},
					}}
				/>
			</Stack.Navigator>
		</NavigationContainer>
	);
}
