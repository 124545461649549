import { useState } from "react";
import { Button, Text, TextInput, View, ScrollView, Image, TouchableOpacity } from "react-native";
import BouncyCheckbox from "react-native-bouncy-checkbox";
import { placeholderTextColor, redHexColor, styles } from "../../styles/styles";
import { supabase } from "../../supabase/supabase";
import CardContainer from "../CardContainer";

export default function Login({ navigation, route }) {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const [error, setError] = useState(null);

	const [showPassword, setShowPassword] = useState(false);

	const showError = (error) => {
		setError(error);
	};

	const signInWithEmailAndPassword = async (e) => {
		const { user, session, error } = await supabase.auth.signIn({
			email,
			password,
		});

		if (error) showError(error);
	};

	const signUp = (e) => {
		navigation.navigate("Register");
	};

	const isLoginButtonDisabled = email === "" || password === "";

	return (
		<CardContainer>
			<View style={styles.titleContainer}>
				<Text style={[styles.title, { fontFamily: "PlayfairDisplay-Bold" }]}>Y... ¿tú eres?</Text>
			</View>

			{error ? (
				<View style={styles.errorContainer}>
					<Text style={[styles.errorText, { fontFamily: "Raleway-Regular" }]}>{"Usuario o contraseña inválidos."}</Text>
				</View>
			) : (
				<></>
			)}

			<View style={styles.formInputContainer}>
				<View>
					<Text style={[styles.inputLabel, { fontFamily: "Raleway-Medium" }]}>Correo electrónico</Text>
				</View>
				<View>
					<TextInput
						style={[styles.textInput, { fontFamily: "Raleway-Regular" }]}
						placeholder="ejemplo@correo.com"
						onChangeText={(email) => setEmail(email)}
						autoComplete="email"
						placeholderTextColor={placeholderTextColor}></TextInput>
				</View>
			</View>

			<View style={styles.formInputContainer}>
				<View>
					<Text style={[styles.inputLabel, { fontFamily: "Raleway-Medium" }]}>Contraseña</Text>
				</View>
				<View>
					<TextInput
						style={styles.textInput}
						placeholder="************"
						secureTextEntry={!showPassword}
						onChangeText={(password) => setPassword(password)}
						placeholderTextColor={placeholderTextColor}></TextInput>
				</View>
				<View style={styles.bouncyCheckBox}>
					<BouncyCheckbox
						size={15}
						fillColor={redHexColor}
						textStyle={{
							textDecorationLine: "none",
							marginLeft: -8,
							fontSize: 12,
							fontFamily: "Raleway-Italic",
						}}
						text="Mostrar contraseña"
						onPress={(showPassword) => {
							setShowPassword(showPassword);
						}}
					/>
				</View>
			</View>

			<View style={styles.buttonContainer}>
				<TouchableOpacity
					style={styles.touchableOpacityButton}
					onPress={signInWithEmailAndPassword}
					disabled={isLoginButtonDisabled}>
					<View style={[styles.button, isLoginButtonDisabled ? styles.buttonDisabled : undefined]}>
						<Text style={[styles.buttonText, isLoginButtonDisabled ? styles.buttonDisabledText : undefined]}>INGRESAR</Text>
					</View>
				</TouchableOpacity>
			</View>

			<View style={styles.buttonContainer}>
				<TouchableOpacity
					style={styles.touchableOpacityButton}
					onPress={signUp}>
					<View style={[styles.button]}>
						<Text style={[styles.buttonText]}>REGISTRARSE</Text>
					</View>
				</TouchableOpacity>
			</View>
		</CardContainer>
	);
}
