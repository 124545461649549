import { Text, TouchableOpacity, View } from "react-native";
import { dateMinusMinutes, datePlusMinutes, getReadableTimeOfTimestamptz, timestamptzToDate } from "../../../helpers/time";
import { invalidPlaceholderTextColor, styles } from "../../../styles/styles";
import { supabase } from "../../../supabase/supabase";
import FontAwesome5 from "@expo/vector-icons/FontAwesome5";
import { getDateDeliverySchedules } from "../../../supabase/supabaseController";

export default function AvailableSchedule(props) {
	const { updateFunction, availableSchedule, setShowMessageAlert, setShowErrorAlert, setShowLoadingAlert, setErrorMessage } = props;

	const user = supabase.auth.user();

	const showError = (message) => {
		setErrorMessage(message);
		setShowErrorAlert(true);
	};

	const deleteAvailableSchedule = async () => {
		setShowLoadingAlert(true);

		let startDate = timestamptzToDate(availableSchedule.start_date);
		let endDate = timestamptzToDate(availableSchedule.end_date);

		let deliverySchedules = await getDateDeliverySchedules();

		let errorDeletingDeliveries;

		deliverySchedules.forEach(async (e) => {
			let date = timestamptzToDate(e.time);
			let deliveryStartDate = dateMinusMinutes(date, e.before_length);
			let deliveryEndDate = datePlusMinutes(date, e.delivery_length + e.after_length);

			if (startDate <= deliveryStartDate && deliveryStartDate <= endDate) {
				const { data, error } = await supabase.from("delivery_schedule").delete().eq("id", e.id);

				if (error) {
					errorDeletingDeliveries = true;
				}
			}
		});

		if (errorDeletingDeliveries) {
			showError("Error en el servidor");
			return;
		}

		const { data, error } = await supabase.from("available_schedule").delete().eq("id", availableSchedule.id);

		if (error) {
			showError("No se ha podido eliminar el registro");
			return;
		}

		await updateFunction();

		setShowMessageAlert(true);
		setShowLoadingAlert(false);
	};

	return (
		<View style={styles.pedidoContainer}>
			<View style={{ flex: 1 }}>
				<View>
					<Text style={styles.availableSchedule}>Horario disponible</Text>
				</View>
				<View>
					<Text style={styles.availableHour}>
						{getReadableTimeOfTimestamptz(availableSchedule.start_date)} - {getReadableTimeOfTimestamptz(availableSchedule.end_date)}
					</Text>
				</View>
			</View>
			{user.user_metadata.isDeliveryPerson ? (
				<View style={{ justifyContent: "center" }}>
					<TouchableOpacity
						style={[styles.button, styles.trashButton, { backgroundColor: invalidPlaceholderTextColor }]}
						color={styles.button.backgroundColor}
						title="->"
						onPress={deleteAvailableSchedule}>
						<FontAwesome5
							name="trash"
							size={20}></FontAwesome5>
					</TouchableOpacity>
				</View>
			) : null}
		</View>
	);
}
