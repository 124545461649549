import { useState } from "react";
import { Button, ScrollView, Text, TouchableOpacity, View } from "react-native";
import AwesomeAlert from "react-native-awesome-alerts";
import { styles } from "../../styles/styles";
import BottomBar from "../BottomBar";
import LoggedTemplate from "../LoggedTemplate";
import SectionContainer from "../SectionContainer";
import AvailableSchedules from "./subcomponents/AvailableSchedules";
import FontAwesome5 from "@expo/vector-icons/FontAwesome5";
import { supabase } from "../../supabase/supabase";
import HeaderSectionContainer from "../HeaderSectionContainer";
import { areDatesInSameDay, dateMinusDays, datePlusDays } from "../../helpers/time";
import moment from "moment/moment";

export default function DeliveryPersonFeed(props) {
	let { navigation, route } = props;

	const [date, setDate] = useState(new Date());

	const [leftPannelVisible, setLeftPannelVisible] = useState(false);

	const [showMessageAlert, setShowMessageAlert] = useState(false);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [showLoadingAlert, setShowLoadingAlert] = useState(false);

	const [errorMessage, setErrorMessage] = useState("");

	const user = supabase.auth.user();

	const hideAlerts = () => {
		setShowMessageAlert(false);
		setShowErrorAlert(false);
	};

	const restartState = () => {};

	let title;

	if (areDatesInSameDay(date, new Date())) {
		title = "Horarios abismales de hoy";
	} else if (date <= new Date()) {
		title = `Horarios abismales de ${moment(date).fromNow()}`;
	} else {
		title = `Horarios abismales ${moment(date).fromNow()}`;
	}

	return (
		<>
			<LoggedTemplate
				navigation={navigation}
				route={route}
				leftPannelVisible={leftPannelVisible}
				setLeftPannelVisible={setLeftPannelVisible}>
				<HeaderSectionContainer
					setLeftPannelVisible={setLeftPannelVisible}
					leftButtonFunction={() => {
						setDate(dateMinusDays(date, 1));
					}}
					rightButtonFunction={() => {
						setDate(datePlusDays(date, 1));
					}}>
					<Text style={[styles.headerHomeText1, {marginBottom: 36}]}>{title}</Text>
				</HeaderSectionContainer>

				<SectionContainer style={{ flexGrow: 1 }}>
					<AvailableSchedules
						date={date}
						setShowMessageAlert={setShowMessageAlert}
						setShowErrorAlert={setShowErrorAlert}
						setShowLoadingAlert={setShowLoadingAlert}
						setErrorMessage={setErrorMessage}></AvailableSchedules>
					{user.user_metadata.isDeliveryPerson ? (
						<TouchableOpacity
							style={styles.touchableOpacityButton}
							onPress={() => {
								navigation.navigate("AvailableForm");
							}}>
							<View style={[styles.button]}>
								<Text style={[styles.buttonText]}>AGREGAR HORARIO LIBRE</Text>
							</View>
						</TouchableOpacity>
					) : null}
				</SectionContainer>
			</LoggedTemplate>
			<AwesomeAlert
				show={showMessageAlert}
				showProgress={false}
				title="¡Perfecto!"
				message="Se ha eliminado correctamente"
				closeOnTouchOutside={true}
				closeOnHardwareBackPress={true}
				showConfirmButton={true}
				confirmText="Okay"
				confirmButtonColor="#c6364d"
				onConfirmPressed={() => {
					hideAlerts();
					restartState();
					navigation.navigate("DeliveryPersonFeed");
				}}
				onDismiss={() => {
					hideAlerts();
					restartState();
					navigation.navigate("DeliveryPersonFeed");
				}}
			/>
			<AwesomeAlert
				show={showErrorAlert}
				showProgress={false}
				title="Error"
				message={errorMessage}
				closeOnTouchOutside={true}
				closeOnHardwareBackPress={true}
				showConfirmButton={true}
				confirmText="Okay"
				confirmButtonColor="#c6364d"
				onConfirmPressed={hideAlerts}
				onDismiss={hideAlerts}
			/>
			<AwesomeAlert
				show={showLoadingAlert}
				showProgress={true}
				title="Cargando..."
				message="Espere un momento, por favor"
				closeOnTouchOutside={false}
				closeOnHardwareBackPress={false}
			/>
		</>
	);
}
