import BottomButton from "./Home/subcomponents/BottomButton";
import SectionContainer from "./SectionContainer";
import { Platform, Text } from "react-native";
import { supabase } from "../supabase/supabase";
import { styles } from "../styles/styles";

export default function BottomBar(props) {
	let { navigation, route } = props;

	let user = supabase.auth.user();

	return (
		<SectionContainer style={styles.barNav}>
			<BottomButton
				name="home"
				onPress={() => {
					navigation.navigate("Home");
				}}></BottomButton>
			<BottomButton
				name="calendar-alt"
				onPress={() => {
					navigation.navigate("Calendar");
				}}></BottomButton>
			<BottomButton
				name="biking"
				onPress={() => {
					navigation.navigate("DeliveryPersonFeed");
				}}></BottomButton>
		</SectionContainer>
	);
}
