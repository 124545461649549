import { Button, Text, TouchableOpacity } from "react-native";
import { styles } from "../../../styles/styles";
import FontAwesome5 from "@expo/vector-icons/FontAwesome5";

export default function BottomButton(props) {
	return (
		<TouchableOpacity
			onPress={props.onPress}
			style={[styles.bottomButton]}>
			<FontAwesome5
				name={props.name}
				color="#c6364d"
				size={20}></FontAwesome5>
		</TouchableOpacity>
	);
}
