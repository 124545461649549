import moment from "moment/moment";
import { capitalizeFirstLetter } from "./string";

export const getLocalTimezone = () => {
	let tz = new Date().toString().match(/([-\+][0-9]+)\s/)[1];

	var position = 3;
	return [tz.slice(0, position), ":", tz.slice(position)].join("");
};

export const getTimeStringFromTimeObject = (time, showSeconds = true) => {
	let { hours, minutes } = time;
	return `${format(hours)}:${format(minutes)}${showSeconds ? ":00" : ""}`;
};

const format = (number) => {
	let string = String(number);
	if (string.length < 2) {
		string = "0" + string;
	}
	return string;
};

export const getTimestamptz = (date = new Date(), time = { hours: 0, minutes: 0 }, tz = getLocalTimezone()) => {
	let year = date.getFullYear();
	let month = format(date.getMonth() + 1);
	let day = format(date.getDate());

	return `${year}-${month}-${day} ${getTimeStringFromTimeObject(time)}${tz}`;
};

export const getDateFromDateAndTimeObjects = (date = new Date(), time = { hours: 0, minutes: 0 }) => {
	let resultDate = new Date(date);

	resultDate.setHours(time.hours);
	resultDate.setMinutes(time.minutes);

	return resultDate;
};

export const getIsEndTimeAfterStartTime = (startTime, endTime) => {
	if (!startTime || !endTime) return true;

	const getTimeAsNumberOfMinutes = (time) => {
		let timeParts = time.split(":");

		let timeInMinutes = parseInt(timeParts[0]) * 60 + parseInt(timeParts[1]);

		return timeInMinutes;
	};

	let startTimeInMinutes = getTimeAsNumberOfMinutes(getTimeStringFromTimeObject(startTime));
	let endTimeInMinutes = getTimeAsNumberOfMinutes(getTimeStringFromTimeObject(endTime));

	return startTimeInMinutes < endTimeInMinutes;
};

export const timestamptzToJSTimestamp = (s, tz = "+00:00") => {
	let b = s.split(/\D/);
	return b[0] + "-" + b[1] + "-" + b[2] + "T" + b[3] + ":" + b[4] + ":" + b[5] + "." + b[6].substr(0, 3) + tz;
};

export const timestamptzToDate = (timestamptz) => {
	return new Date(Date.parse(timestamptzToJSTimestamp(timestamptz)));
};

export const dateMinusDays = (date, days) => {
	let resultDate = new Date(date);
	resultDate.setDate(resultDate.getDate() - days);

	return resultDate;
};

export const datePlusDays = (date, days) => {
	let resultDate = new Date(date);
	resultDate.setDate(resultDate.getDate() + days);

	return resultDate;
};

export const dateMinusMinutes = (date, minutes) => {
	let MS_PER_MINUTE = 60000;
	let resultDate = new Date(date.getTime() - minutes * MS_PER_MINUTE);

	return resultDate;
};

export const datePlusMinutes = (date, minutes) => {
	let MS_PER_MINUTE = 60000;
	let resultDate = new Date(date.getTime() + minutes * MS_PER_MINUTE);

	return resultDate;
};

export const getDateOfDayOfTheWeek = (i, date) => {
	let dateAux = new Date(date.getTime());
	dateAux.setDate(dateAux.getDate() + (i - date.getDay()) /* -1 */ /* Menos uno para iniciar en lunes */);

	return dateAux;
};

export const getMonth = (date, mode) => {
	if (mode === "daily") return capitalizeFirstLetter(moment(date).format("MMMM"));

	let dateFirstDayOfTheWeek = getDateOfDayOfTheWeek(0, date);
	let dateLastDayOfTheWeek = getDateOfDayOfTheWeek(6, date);

	let string = capitalizeFirstLetter(moment(dateFirstDayOfTheWeek).format("MMMM"));
	if (dateFirstDayOfTheWeek.getMonth() !== dateLastDayOfTheWeek.getMonth()) string += " - " + capitalizeFirstLetter(moment(dateLastDayOfTheWeek).format("MMMM"));

	return string;
};

export const getIsdateBetweenDates = (date, dateBetweenStart, dateBetweenEnd) => {
	return dateBetweenStart <= date && date <= dateBetweenEnd;
};

export const getTimeOfDate = (date) => {
	return { hours: date.getHours(), minutes: date.getMinutes() };
};

export const getTimeOfTimestamptz = (timestamptz) => {
	return getTimeOfDate(timestamptzToDate(timestamptz));
};

export const getReadableTimeOfDate = (date) => {
	return `${format(date.getHours())}:${format(date.getMinutes())}`;
};

export const getReadableTimeOfTimestamptz = (timestamptz) => {
	return getReadableTimeOfDate(timestamptzToDate(timestamptz));
};

export const areDatesInSameDay = (d1, d2) => {
	return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
};
