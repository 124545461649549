import { Text, View } from "react-native";
import LeftPannel from "./LeftPannel";
import BottomBar from "./BottomBar";
import { useState } from "react";
import { supabase } from "../supabase/supabase";
// import { ScrollView } from "react-native-gesture-handler";
import { ScrollView } from "react-native";

export default function LoggedTemplate(props) {
	let { navigation, route } = props;

	let { leftPannelVisible, setLeftPannelVisible } = props;

	const user = supabase.auth.user();

	return (
		<View style={{ height: "100%" }}>
			<ScrollView
				style={{ flex: 1, flexDirection: "column" }}
				contentContainerStyle={{ flexGrow: 1 }}
				nestedScrollEnabled={true}>
				{props.children}
			</ScrollView>

			{user !== null ? (
				<>
					<BottomBar
						navigation={navigation}
						route={route}></BottomBar>

					<LeftPannel
						navigation={navigation}
						route={route}
						visible={leftPannelVisible}
						setVisible={setLeftPannelVisible}></LeftPannel>
				</>
			) : null}
		</View>
	);
}
