import { removeItemAll } from "../helpers/arrays";
import { getTimestamptz } from "../helpers/time";
import { supabase } from "./supabase";

const getDateSchedule = async (rpcName, date) => {
	let timestamptz = getTimestamptz(date);

	let { data: schedule, error } = await supabase.rpc(rpcName, { arg_date: timestamptz });

	return schedule;
};

export const getDateAvailableSchedules = async (date) => {
	return await getDateSchedule("get_date_available_schedules", date);
};

export const getDateDeliverySchedules = async (date) => {
	return await getDateSchedule("get_date_delivery_schedules", date);
};

export const sendNewDeliveryNotifications = async () => {
	let { data: users_data, error } = await supabase.from("users_data").select("is_delivery_person,expo_push_tokens");

	users_data.forEach((user_data) => {
		if (user_data.is_delivery_person)
			user_data.expo_push_tokens.forEach(async (expo_push_token) => {
				console.log("Se ha enviado una notificación a: ", expo_push_token);

				await sendPushNotification(expo_push_token);
			});
	});
};

const sendPushNotification = async (expoPushToken) => {
	await fetch("https://exp.host/--/api/v2/push/send", {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Accept-encoding": "gzip, deflate",
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			to: expoPushToken,
			title: "Nueva entrega!",
			body: "Nueva entrega!",
			sound: "default",
		}),
		mode: "no-cors",
	});
};

const modifyExpoPushToken = async (user, expoPushToken, modifyFunc, updateFunc) => {
	let data, error;

	({ data, error } = await supabase.from("users_data").select("expo_push_tokens").eq("user_id", user.id));

	let expo_push_tokens = data;

	expo_push_tokens = expo_push_tokens[0].expo_push_tokens;

	expo_push_tokens = modifyFunc(expo_push_tokens, expoPushToken);

	({ data, error } = await supabase.from("users_data").update({ expo_push_tokens: expo_push_tokens }).eq("user_id", user.id));

	if (error) console.log(error);
};

export const addExpoPushToken = async (user, expoPushToken) => {
	await modifyExpoPushToken(user, expoPushToken, (expoPushTokens, expoPushToken) => {
		if (expoPushTokens.indexOf(expoPushToken) === -1) {
			expoPushTokens.push(expoPushToken);
		}
		return [...expoPushTokens];
	});
};

export const removeExpoPushToken = async (user, expoPushToken) => {
	await modifyExpoPushToken(user, expoPushToken, (expoPushTokens, expoPushToken) => {
		return removeItemAll(expoPushTokens, expoPushToken);
	});
};
