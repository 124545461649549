import { useIsFocused } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { View, Text } from "react-native";
import { dateMinusMinutes, datePlusMinutes, timestamptzToDate } from "../../../helpers/time";
import { moment } from "../../../moment/moment";
import { capitalizeFirstLetter } from "../../../helpers/string";
import { calendarBorderColor, calendarLabelColor, redHexColor, styles } from "../../../styles/styles";
import { supabase } from "../../../supabase/supabase";
import { getDateAvailableSchedules, getDateDeliverySchedules } from "../../../supabase/supabaseController";

export default function CalendarDay(props) {
	const { date, setShowLoadingAlert } = props;

	const [availableSchedule, setAvailableSchedule] = useState();
	const [deliverySchedule, setDeliverySchedule] = useState();

	const user = supabase.auth.user();

	useEffect(() => {
		(async () => {
			setShowLoadingAlert(true);

			setAvailableSchedule(await getDateAvailableSchedules(date));
			setDeliverySchedule(await getDateDeliverySchedules(date));

			setShowLoadingAlert(false);
		})();
	}, [props.refreshData]);

	return (
		<View style={[{ flexGrow: 1, flexShrink: 0.142857143, height: "100%" }, props.style]}>
			<View style={{ height: 50, alignItems: "center", justifyContent: "center", textAlign: "center" }}>
				<View>
					<Text style={{ color: calendarLabelColor }}>{capitalizeFirstLetter(moment(date).format("dd"))}</Text>
					<Text style={{ color: calendarLabelColor }}>{moment(date).format("D")}</Text>
				</View>
			</View>
			<View style={{ flexGrow: 1 }}>
				{(() => {
					let array = [];
					for (let i = 0; i < 24; i++) {
						const boxHeigth = 100 / 24;

						array.push(
							<View
								style={{ height: `${boxHeigth}%`, flexDirection: "row", borderWidth: 1, borderColor: calendarBorderColor, borderBottomWidth: 0, borderLeftWidth: 0 }}
								key={i}></View>
						);
					}
					return array;
				})()}

				{availableSchedule
					? availableSchedule.map((e, index) => {
							if (!deliverySchedule)
								return (
									<View
										style={[
											styles.calendarBox,
											{
												backgroundColor: "#efd5e3",
												top: getPercentageOfTimestamptz(e.start_date) + "%",
												height: getPercentageOfTimestamptz(e.end_date) - getPercentageOfTimestamptz(e.start_date) + "%",
											},
										]}
										key={index}>
										<Text style={{ color: "white", margin: 10 }}></Text>
									</View>
								);
							else {
								let startDate = timestamptzToDate(e.start_date);
								let endDate = timestamptzToDate(e.end_date);

								let relativeStartDate = new Date(startDate);

								return (
									<View
										style={[
											{
												position: "absolute",
												width: "100%",
												height: "100%",
											},
										]}
										key={index}>
										{deliverySchedule.map((e, index) => {
											let date = timestamptzToDate(e.time);
											let deliveryStartDate = dateMinusMinutes(date, e.before_length);
											let deliveryEndDate = datePlusMinutes(date, e.delivery_length + e.after_length);

											if (startDate <= deliveryStartDate && deliveryStartDate <= endDate) {
												let startDate = new Date(relativeStartDate);

												relativeStartDate = new Date(deliveryEndDate);

												return (
													<View
														style={[
															styles.calendarBox,
															{
																backgroundColor: "#efd5e3",
																top: getPercentageOfDate(startDate) + "%",
																height: getPercentageOfDate(deliveryStartDate) - getPercentageOfDate(startDate) + "%",
															},
														]}
														key={index}>
														{index === 0 || user === null ? <Text style={{ color: "black", margin: 10 }}></Text> : null}
													</View>
												);
											}
										})}
										<View
											style={[
												styles.calendarBox,
												{
													backgroundColor: "#efd5e3",
													top: getPercentageOfDate(relativeStartDate) + "%",
													height: getPercentageOfDate(endDate) - getPercentageOfDate(relativeStartDate) + "%",
												},
											]}>
											{relativeStartDate.getTime() === startDate.getTime() || user === null ? <Text style={{ color: "black", margin: 10 }}></Text> : null}
										</View>
									</View>
								);
							}
					  })
					: null}

				{user !== null && deliverySchedule
					? deliverySchedule.map((e, index) => {
							let date = timestamptzToDate(e.time);

							let startDate = dateMinusMinutes(date, e.before_length);

							let endDeliveryTime = datePlusMinutes(date, e.delivery_length);
							let endDate = datePlusMinutes(date, e.delivery_length + e.after_length);

							return (
								<View
									style={[
										{
											position: "absolute",
											width: "100%",
											height: "100%",
										},
									]}
									key={index}>
									<View
										style={[
											styles.calendarBox,
											{
												backgroundColor: redHexColor,
												top: getPercentageOfDate(startDate) + "%",
												height: getPercentageOfDate(date) - getPercentageOfDate(startDate) + "%",
											},
										]}></View>
									<View
										style={[
											styles.calendarBox,
											{
												backgroundColor: "#DD7481",
												top: getPercentageOfDate(date) + "%",
												height: getPercentageOfDate(endDeliveryTime) - getPercentageOfDate(date) + "%",
											},
										]}></View>
									<View
										style={[
											styles.calendarBox,
											{
												backgroundColor: redHexColor,
												top: getPercentageOfDate(endDeliveryTime) + "%",
												height: getPercentageOfDate(endDate) - getPercentageOfDate(endDeliveryTime) + "%",
											},
										]}></View>
								</View>
							);
					  })
					: null}
			</View>
		</View>
	);
}

const timeToPercentage = (time) => {
	const { hours, minutes } = time;

	const hourPercentage = 100 / 24;
	const minutePercentage = hourPercentage / 60;

	return hours * hourPercentage + minutes * minutePercentage;
};

const getTimeOfDate = (date) => ({
	hours: date.getHours(),
	minutes: date.getMinutes(),
});

const getPercentageOfDate = (date) => {
	return timeToPercentage(getTimeOfDate(date));
};

const getTimeOfTimestamptz = (timestamptz) => {
	let date = timestamptzToDate(timestamptz);

	return getTimeOfDate(date);
};

const getPercentageOfTimestamptz = (timestamptz) => {
	return timeToPercentage(getTimeOfTimestamptz(timestamptz));
};
