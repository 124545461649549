import { useIsFocused } from "@react-navigation/native";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { Text, View } from "react-native";
import { areDatesInSameDay } from "../../../helpers/time";
import { styles } from "../../../styles/styles";
import { supabase } from "../../../supabase/supabase";
import { getDateDeliverySchedules } from "../../../supabase/supabaseController";
import Pedido from "./Pedido";

export default function Pedidos(props) {
	const { date } = props;

	const [deliveries, setDeliveries] = useState();

	const isFocused = useIsFocused();

	const setTodaysDeliveries = async () => {
		setDeliveries(await getDateDeliverySchedules(date));
	};

	useEffect(() => {
		(async () => {
			await setTodaysDeliveries();
		})();
	}, [isFocused, date]);

	let text;

	if (areDatesInSameDay(date, new Date())) {
		text = "No hay pedidos para hoy";
	} else if (date <= new Date()) {
		text = `No hay pedidos de ${moment(date).fromNow()}`;
	} else {
		text = `No hay pedidos ${moment(date).fromNow()}`;
	}

	return (
		<View style={styles.pedidosContainer}>
			{deliveries !== undefined ? (
				deliveries.length > 0 ? (
					deliveries.map((delivery, index) => (
						<Pedido
							key={index}
							delivery={delivery}
							updateFunction={setTodaysDeliveries}
							{...props}></Pedido>
					))
				) : (
					<Text style={styles.noPedidos}>{text}</Text>
				)
			) : (
				<Text>Cargando...</Text>
			)}
		</View>
	);
}
